import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../../components/layoutDoc";
import Seo from "../../components/seo";

import Matt from '../../images/listing-managers/matt.png'
import Peter from '../../images/listing-managers/Peter.png'
import Patrick from '../../images/listing-managers/Patrick.png'
import AnkurBorah from '../../images/listing-managers/Ankur-Borah.png'
import Ferdi from '../../images/listing-managers/Ferdi.png'
import Dagz from '../../images/listing-managers/Dagz.png'
import Michael from '../../images/listing-managers/Michael.png'
import AK from '../../images/listing-managers/AK.png'
import Franklin from '../../images/listing-managers/Franklin.png'
import Brandon from '../../images/listing-managers/Brandon.png'
import Edmund from '../../images/listing-managers/Edmund.png'

const DATA = [
    {
        image: Patrick,
        name: 'Patrick Lau',
        telegram: '@patl1515'
    },
    {
        image: Matt,
        name: 'Matt',
        telegram: '@Matt2'
    },
    {
        image: Peter,
        name: 'Peter',
        telegram: '@Irony_128'
    },
    {
        image: AnkurBorah,
        name: 'Ankur',
        telegram: '@Zed_Lepelin <br />@SirMarshal @Marshal_B'
    },
    {
        image: Ferdi,
        name: 'Ferdinan',
        telegram: '@kingferdii <br />@kingferdi21'
    },
    {
        image: Dagz,
        name: 'Dagzy',
        telegram: '@DagzyBunny'
    },
    {
        image: Michael,
        name: 'Michael',
        telegram: '@Michaeloneday'
    },
    {
        image: AK,
        name: 'AK',
        telegram: '@therealayush1'
    },
    {
        image: Franklin,
        name: 'Franklin',
        telegram: '@Franklin5522 <br />@Fazle32'
    },
    {
        image: Brandon,
        name: 'Brandon',
        telegram: '@brandon_dev'
    },
    {
        image: Edmund,
        name: 'Edmund',
        telegram: '@ImWongg_Vegeta'
    }
]

function Manager() {
    return (
        <Layout page="Listing Manager" pageInner="Listing Manager">
            <ScrollUpButton ContainerClassName="custom-scroll-button" />
            <Seo title="Listing Manager" />
            <Container fluid='md' className="py-md-5 py-4">
                <Row className="justify-content-center text-center mb-lg-5 mb-3">
                    <Col lg={12} className="mb-5">
                        <div className="section-title">
                            <h2>Listing Manager</h2>
                        </div>
                        <p>HedgePay Agents are individuals engaged by us to promote and raise awareness of the launchpad. They do so by endorsing, recommending, and referring our services to upcoming crypto projects, influencers, investors, blockchain owners etc.</p>
                    </Col>
                </Row>

                <Row className="justify-content-center">
                    {DATA?.map((item, index) => (
                        <Col md={4} key={index} className="mb-md-5 pb-5 mb-4 text-center">
                            <div className='list-manager'>
                                <div className='list-manager-image'><img src={item.image} alt={item.name} /></div>
                                <h3>{item.name}</h3>
                                <p>
                                    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="#fff"/>
                                    </svg>
                                    <span dangerouslySetInnerHTML={{__html: item?.telegram}} />
                                </p>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </Layout>
    );
}

export default Manager;
